.password-rules {
    position: absolute;
    width: 400px; 
    background-color: white; 
    border: 1px solid #ccc; 
    padding: 1rem;
	margin-left: 1.5rem;
	z-index: 100;
}

.password-does-not-match {
	position: absolute;
	margin-left: 1.5rem;
	color: red;
}

.error-text-field {
  	color: red;
}